import React from 'react'

const SearchIcon = ({ active, color }) => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.7499 0.5C6.51076 0.5 4.27162 1.35416 2.56247 3.06247C0.854157 4.77162 0 7.01076 0 9.2499C0 11.489 0.854157 13.729 2.56247 15.4373C4.27162 17.1457 6.51076 17.9998 8.7499 17.9998C10.7286 17.9998 12.708 17.3328 14.3205 15.9987L18.5774 20.2556C18.9024 20.5814 19.4307 20.5814 19.7557 20.2556C20.0815 19.9306 20.0815 19.4022 19.7557 19.0772L15.4989 14.8204C16.8328 13.2079 17.4998 11.2286 17.4998 9.2499C17.4998 7.01076 16.6457 4.77162 14.9373 3.06247C13.229 1.35416 10.989 0.5 8.7499 0.5ZM13.7638 14.2542C15.1431 12.8715 15.8332 11.0611 15.8332 9.2499C15.8332 7.43742 15.1415 5.62411 13.759 4.24079C12.3757 2.85831 10.5632 2.16665 8.7499 2.16665C6.93742 2.16665 5.12411 2.85831 3.74079 4.24079C2.35831 5.62411 1.66665 7.43742 1.66665 9.2499C1.66665 11.0632 2.35831 12.8757 3.74079 14.259C5.12411 15.6415 6.93742 16.3332 8.7499 16.3332C10.5612 16.3332 12.3716 15.6431 13.7543 14.2637C13.7559 14.2621 13.7575 14.2605 13.7591 14.2589C13.7606 14.2574 13.7622 14.2558 13.7638 14.2542Z"
                fill={color ? color : active ? '#FAD810' : '#888888'}
            />
        </svg>
    )
}
export default SearchIcon
