import React from 'react'

const Apple = ({ active }) => {
    return (
        <svg
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.1269 2.60699C12.5927 2.03689 13.2848 1.55588 13.9801 1.27086C13.8433 2.00243 13.4949 2.70649 13.0478 3.28284L13.0463 3.28474C12.5726 3.89854 11.872 4.3911 11.1507 4.62643C11.278 3.90996 11.6484 3.18927 12.1251 2.6091L12.1269 2.60699Z"
                stroke={active ? '#FAD810' : '#707070'}
                strokeWidth="2"
            />
            <path
                d="M6.81743 23.0004L6.81849 23.0004C7.32442 23.0052 7.62774 22.8699 8.17104 22.6274C8.19891 22.615 8.22741 22.6023 8.2566 22.5893C8.88729 22.3086 9.67278 21.9926 10.9287 21.9863L6.81743 23.0004ZM6.81743 23.0004C6.28356 22.9958 5.73634 22.732 5.15054 22.1921M6.81743 23.0004L5.15054 22.1921M15.0063 22.9974L15.0092 22.9973C15.5155 22.9935 16.0747 22.7118 16.697 22.112C17.3104 21.5207 17.8652 20.7363 18.3629 19.9738C18.8547 19.2202 19.1188 18.7476 19.4336 18.0839C15.8298 15.9605 15.1864 10.6699 18.5254 7.8891C17.5759 7.05394 16.4673 6.61792 15.4698 6.61792C14.5729 6.61792 13.9406 6.82634 13.2969 7.06893C13.2358 7.09193 13.1732 7.11597 13.109 7.14062C12.5158 7.3683 11.786 7.64841 10.9114 7.64841C9.99639 7.64841 9.19311 7.3592 8.53899 7.1237C8.49429 7.1076 8.45028 7.09176 8.40697 7.07627C7.6948 6.82159 7.07923 6.62092 6.3364 6.62092C4.9043 6.62092 3.25002 7.51482 2.16663 9.19169C1.45845 10.29 1.10849 11.9804 1.30321 13.9753C1.49622 15.9527 2.21843 18.1411 3.53782 20.1627L15.0063 22.9974ZM15.0063 22.9974C14.513 23.0026 14.2253 22.8706 13.698 22.6287M15.0063 22.9974L13.698 22.6287M5.15054 22.1921C4.56409 21.6516 4.03155 20.9191 3.53786 20.1628L5.15054 22.1921ZM13.698 22.6287C13.6663 22.6142 13.6339 22.5993 13.6004 22.584M13.698 22.6287L13.6004 22.584M13.6004 22.584C12.9732 22.2973 12.1917 21.9793 10.9289 21.9863L13.6004 22.584Z"
                stroke={active ? '#FAD810' : '#707070'}
                strokeWidth="2"
            />
        </svg>
    )
}
export default Apple
