import React from 'react'

const Android = ({ active }) => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1391_29418)">
                <path
                    d="M10.879 4.06981C10.6803 3.97881 10.4582 3.97093 10.2535 4.04747C10.049 4.12382 9.88619 4.27562 9.79556 4.47484C9.60824 4.88591 9.78952 5.37315 10.1993 5.56084C10.3079 5.61046 10.4231 5.63554 10.5388 5.63554C10.6353 5.63554 10.732 5.61815 10.825 5.58336C11.0295 5.507 11.1923 5.35521 11.2829 5.15599C11.3737 4.95677 11.3818 4.73412 11.3054 4.52904C11.2291 4.32396 11.0777 4.16081 10.879 4.06981Z"
                    fill={active ? '#FAD810' : '#707070'}
                />
                <path
                    d="M14.7164 4.06981C14.5179 3.97881 14.2957 3.97093 14.0909 4.04747C13.8864 4.12382 13.7238 4.27562 13.633 4.47465C13.5422 4.67387 13.5343 4.89653 13.6105 5.10161C13.6868 5.30687 13.8383 5.46983 14.0369 5.56084C14.1453 5.61046 14.2607 5.63554 14.3764 5.63554C14.4727 5.63554 14.5694 5.61815 14.6624 5.58336C14.8671 5.50682 15.0297 5.35503 15.1205 5.15581C15.3077 4.74474 15.1264 4.25749 14.7164 4.06981Z"
                    fill={active ? '#FAD810' : '#707070'}
                />
                <path
                    d="M2.59857 9.03485H2.5744C1.43054 9.03485 0.5 9.96777 0.5 11.1144V16.1082C0.5 17.2546 1.43054 18.1876 2.5744 18.1876H2.59857C3.74243 18.1876 4.67297 17.2546 4.67297 16.1082V11.1144C4.67297 9.96777 3.74243 9.03485 2.59857 9.03485ZM3.26672 16.1082C3.26672 16.4775 2.96698 16.778 2.59857 16.778H2.5744C2.20599 16.778 1.90625 16.4775 1.90625 16.1082V11.1144C1.90625 10.7451 2.20599 10.4446 2.5744 10.4446H2.59857C2.96698 10.4446 3.26672 10.7451 3.26672 11.1144V16.1082Z"
                    fill={active ? '#FAD810' : '#707070'}
                />
                <path
                    d="M22.4257 9.03485H22.4016C21.2577 9.03485 20.3271 9.96777 20.3271 11.1144V16.108C20.3271 17.2546 21.2577 18.1874 22.4016 18.1874H22.4257C23.5696 18.1874 24.5001 17.2546 24.5001 16.108V11.1144C24.5001 9.96777 23.5696 9.03485 22.4257 9.03485ZM23.0939 16.1082C23.0939 16.4775 22.7941 16.778 22.4257 16.778H22.4016C22.0331 16.778 21.7334 16.4775 21.7334 16.1082V11.1144C21.7334 10.7451 22.0331 10.4446 22.4016 10.4446H22.4257C22.7941 10.4446 23.0939 10.7451 23.0939 11.1144V16.1082Z"
                    fill={active ? '#FAD810' : '#707070'}
                />
                <path
                    d="M5.64502 19.1954H7.88129V21.9209C7.88129 23.0675 8.81732 24.0002 9.96777 24.0002C11.1182 24.0002 12.0543 23.0675 12.0543 21.9209V19.1954H12.907V21.9209C12.907 23.0675 13.843 24.0002 14.9935 24.0002C16.1439 24.0002 17.08 23.0675 17.08 21.9209V19.1954H19.2703V9.30988H5.64502V19.1954ZM7.05127 10.7194H17.8638V17.7858H15.6737V21.9209C15.6737 22.2902 15.3685 22.5907 14.9935 22.5907C14.6183 22.5907 14.3132 22.2902 14.3132 21.9209V17.7858H10.6478V21.9209C10.6478 22.2902 10.3428 22.5907 9.96777 22.5907C9.59259 22.5907 9.28754 22.2902 9.28754 21.9209V17.7858H7.05109V10.7194H7.05127Z"
                    fill={active ? '#FAD810' : '#707070'}
                />
                <path
                    d="M19.0304 0.835205L17.8973 0.000244141L16.4226 2.01111C15.3049 1.21259 13.9355 0.741821 12.4575 0.741821C11.1592 0.741821 9.9447 1.1051 8.91089 1.73462L7.63904 0.000244141L6.50598 0.835205L7.79047 2.58643C6.47046 3.82294 5.64502 5.57599 5.64502 7.51746V8.22223H19.2701V7.51746C19.2701 5.75763 18.5919 4.15253 17.4819 2.94659L19.0304 0.835205ZM7.0976 6.8125C7.44659 4.18567 9.71729 2.15155 12.4576 2.15155C15.1978 2.15155 17.4685 4.18567 17.8175 6.8125H7.0976Z"
                    fill={active ? '#FAD810' : '#707070'}
                />
            </g>
            <defs>
                <clipPath id="clip0_1391_29418">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5 0.000244141)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default Android
