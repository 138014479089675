import React from 'react'

const Profile = ({ active }) => {
    return (
        <svg
            width="18"
            height="24"
            viewBox="0 0 18 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.1919 6.88515C13.7195 6.88515 13.3361 6.50177 13.3361 6.02941C13.3361 5.55618 13.7195 5.17367 14.1919 5.17367C15.1306 5.17367 15.9855 5.55789 16.6068 6.1783L16.6119 6.18344C17.2306 6.80471 17.6148 7.65873 17.6148 8.59663V20.577C17.6148 21.5149 17.2297 22.3698 16.6102 22.9919L16.605 22.997C15.9829 23.6157 15.1289 23.9999 14.1919 23.9999H3.92296C2.98079 23.9999 2.12505 23.6149 1.50464 22.9953C1.48753 22.9773 1.47127 22.9594 1.45501 22.9414C0.864546 22.3253 0.5 21.4918 0.5 20.577V3.46219C0.5 2.98896 0.883372 2.60645 1.35574 2.60645C1.82811 2.60645 2.21148 2.98896 2.21148 3.46219V20.577C2.21148 21.0297 2.38948 21.443 2.67786 21.7502L2.71466 21.7844C3.02529 22.0951 3.45316 22.2885 3.92296 22.2885H14.1919C14.6642 22.2885 15.0912 22.0968 15.4002 21.7879L15.4036 21.7844C15.7116 21.4764 15.9033 21.0485 15.9033 20.577V8.59663C15.9033 8.12426 15.7116 7.69639 15.4036 7.38832L15.4002 7.3849C15.0912 7.07598 14.6642 6.88515 14.1919 6.88515Z"
                fill={active ? '#FAD810' : '#888888'}
            />
            <path
                d="M14.1722 5.17382C14.6454 5.17382 15.0279 5.55633 15.0279 6.02956C15.0279 6.50193 14.6454 6.8853 14.1722 6.8853H3.94265C2.99876 6.8853 2.13874 6.49765 1.51405 5.87467L1.50892 5.86868C0.885939 5.24399 0.5 4.38482 0.5 3.44265C0.5 2.49534 0.886795 1.63447 1.51063 1.01063C2.13361 0.386796 2.99534 0 3.94265 0H14.1919C14.6642 0 15.0476 0.383373 15.0476 0.855742C15.0476 1.32811 14.6642 1.71148 14.1919 1.71148H3.94265C3.46685 1.71148 3.0347 1.90659 2.72065 2.22065C2.40659 2.53471 2.21148 2.96771 2.21148 3.44265C2.21148 3.92016 2.40574 4.35231 2.71722 4.66465L2.72065 4.66808C3.03214 4.97957 3.46514 5.17382 3.94265 5.17382H14.1722Z"
                fill={active ? '#FAD810' : '#888888'}
            />
            <path
                d="M15.0474 5.98552C15.0474 6.45875 14.664 6.84126 14.1917 6.84126C13.7185 6.84126 13.3359 6.45875 13.3359 5.98552V0.859645C13.3359 0.387277 13.7185 0.00390625 14.1917 0.00390625C14.664 0.00390625 15.0474 0.387277 15.0474 0.859645V5.98552Z"
                fill={active ? '#FAD810' : '#888888'}
            />
            <path
                d="M7.76828 13.7412C8.5915 13.7412 9.33771 14.0767 9.87854 14.6175L9.88196 14.6209C10.4228 15.1617 10.7582 15.9079 10.7582 16.7312C10.7582 17.5561 10.4228 18.304 9.88196 18.8448C9.34113 19.3857 8.59407 19.7211 7.76828 19.7211C6.94677 19.7211 6.20056 19.3865 5.65802 18.8448L5.65289 18.8397C5.11292 18.2972 4.77832 17.5518 4.77832 16.7312C4.77832 15.9054 5.11377 15.1583 5.6546 14.6175C6.19543 14.0767 6.94334 13.7412 7.76828 13.7412ZM8.67365 15.8258C8.4426 15.5956 8.12256 15.4527 7.76828 15.4527C7.41571 15.4527 7.09567 15.5965 6.86462 15.8275C6.63357 16.0586 6.4898 16.3777 6.4898 16.7312C6.4898 17.0863 6.63185 17.4063 6.86119 17.6348L6.86462 17.6383C7.0931 17.8676 7.41315 18.0096 7.76828 18.0096C8.1217 18.0096 8.44089 17.8659 8.67194 17.6348C8.90299 17.4038 9.04676 17.0846 9.04676 16.7312C9.04676 16.3769 8.90385 16.0568 8.67365 15.8258Z"
                fill={active ? '#FAD810' : '#888888'}
            />
            <path
                d="M10.7574 16.2981C10.7574 16.7704 10.3749 17.1538 9.90164 17.1538C9.42927 17.1538 9.0459 16.7704 9.0459 16.2981V10.3079C9.0459 9.83466 9.42927 9.45215 9.90164 9.45215C10.3749 9.45215 10.7574 9.83466 10.7574 10.3079V16.2981Z"
                fill={active ? '#FAD810' : '#888888'}
            />
            <path
                d="M9.90164 11.1636C9.42927 11.1636 9.0459 10.7803 9.0459 10.3079C9.0459 9.83466 9.42927 9.45215 9.90164 9.45215C10.5999 9.45215 11.2597 9.63784 11.8365 9.96303C12.4321 10.2993 12.9301 10.782 13.2793 11.3604C13.5231 11.7644 13.3931 12.2898 12.9883 12.5337C12.5844 12.7776 12.059 12.6475 11.8151 12.2427C11.614 11.9098 11.3324 11.6351 11.0004 11.4469C10.6804 11.2663 10.3056 11.1636 9.90164 11.1636Z"
                fill={active ? '#FAD810' : '#888888'}
            />
        </svg>
    )
}

export default Profile
