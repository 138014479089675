import { BukaApp } from '../BukaApp'
import Android from './icons/Android'
import Apple from './icons/Apple'
import Collection from './icons/Collection'
import Home from './icons/Home'
import Profile from './icons/Profile'
import SearchIcon from './icons/SearchIcon'
import {
    TRIGGER_COLLECTION_PAGE_VIEWED,
    TRIGGER_PROFILE_PAGE_VIEWED,
} from '@/helpers/events'
// import { useRouter } from 'next/router'
import { updateNav } from '@/store/actions/home'
import {
    setShowDownloadPrompt,
    setShowLoginPrompt,
} from '@/store/actions/player'
import {
    updateFocus,
    updateSearchTerm,
    getSearchResult,
} from '@/store/actions/search'
import { _activeNav } from '@/store/selectors/home'
import { NAVTYPES } from '@/store/types/home'
import { NavItem, Title } from '@/styles/styles.sidebar'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

export default function Sidebar() {
    const { data: session, status } = useSession()

    const activeNav = useSelector(_activeNav)
    // const router = useRouter()

    const dispatch = useDispatch()

    const isNavActive = (currentNav) =>
        activeNav === currentNav ? true : false

    const switchNav = (currentNav) => {
        switch (currentNav) {
            case NAVTYPES.HOME: {
                dispatch(updateNav(currentNav))
                // router.push('/')
                break
            }
            case NAVTYPES.SEARCH: {
                dispatch(updateNav(currentNav))
                dispatch(updateFocus(false))
                dispatch(updateSearchTerm(''))
                dispatch(getSearchResult({ data: [] }))
                // router.push('/search')
                break
            }
            case NAVTYPES.COLLECTION: {
                TRIGGER_COLLECTION_PAGE_VIEWED()
                if (status === 'authenticated' && session?.isLoggedin) {
                    dispatch(updateNav(currentNav))
                } else {
                    dispatch(setShowLoginPrompt(true))
                }
                break
            }
            case NAVTYPES.PROFILE: {
                TRIGGER_PROFILE_PAGE_VIEWED()
                if (status === 'authenticated' && session?.isLoggedin) {
                    dispatch(updateNav(currentNav))
                } else {
                    dispatch(setShowLoginPrompt(true))
                }
                break
            }
            case NAVTYPES.SYARAT_DAN_KETENTUAN: {
                dispatch(updateNav(currentNav))
                break
            }
            case NAVTYPES.KEBIJAKAN_PRIVASI: {
                dispatch(updateNav(currentNav))

                break
            }
            default:
                dispatch(setShowDownloadPrompt(true))
        }
    }
    return (
        <div className="sidebar">
            <div className="sidebarLogo">
                <Link href={'/'} prefetch={false}>
                    <a>
                        <img
                            src={'/img/new-noice-logo.svg'}
                            alt="Noice Logo"
                            width="100"
                            height="29"
                            loading="lazy"
                        />
                    </a>
                </Link>
            </div>

            <div className="sidebarSection">
                <nav>
                    <Link href={'/'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.HOME) ? `active` : ''
                            }
                        >
                            <NavItem onClick={() => switchNav(NAVTYPES.HOME)}>
                                <Home active={isNavActive(NAVTYPES.HOME)} />
                                <Title active={isNavActive(NAVTYPES.HOME)}>
                                    {NAVTYPES.HOME}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>
                    {/* <Link href={'/'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.BUKA_APP) ? `active` : ''
                            }
                        >
                            <NavItem
                                onClick={() => switchNav(NAVTYPES.BUKA_APP)}
                            >
                                <BukaAppIcon
                                    active={isNavActive(NAVTYPES.BUKA_APP)}
                                />
                                <Title active={isNavActive(NAVTYPES.BUKA_APP)}>
                                    {NAVTYPES.BUKA_APP}
                                </Title>
                            </NavItem>
                        </a>
                    </Link> */}
                    {/* <BukaApp isSidebar /> */}
                    <Link href={'/search'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.SEARCH) ? `active` : ''
                            }
                        >
                            <NavItem onClick={() => switchNav(NAVTYPES.SEARCH)}>
                                <SearchIcon
                                    active={isNavActive(NAVTYPES.SEARCH)}
                                />
                                <Title active={isNavActive(NAVTYPES.SEARCH)}>
                                    {NAVTYPES.SEARCH}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>
                    <Link
                        href={
                            status === 'authenticated' && session?.isLoggedin
                                ? '/collection'
                                : '#/'
                        }
                        prefetch={false}
                    >
                        <a>
                            <NavItem
                                onClick={() => switchNav(NAVTYPES.COLLECTION)}
                            >
                                <Collection
                                    active={isNavActive(NAVTYPES.COLLECTION)}
                                />
                                <Title
                                    active={isNavActive(NAVTYPES.COLLECTION)}
                                >
                                    {NAVTYPES.COLLECTION}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>
                    <Link
                        href={
                            status === 'authenticated' && session?.isLoggedin
                                ? '/my-profile'
                                : '#/'
                        }
                        prefetch={false}
                    >
                        <a>
                            <NavItem
                                onClick={() => switchNav(NAVTYPES.PROFILE)}
                            >
                                <Profile
                                    active={isNavActive(NAVTYPES.PROFILE)}
                                />
                                <Title active={isNavActive(NAVTYPES.PROFILE)}>
                                    {NAVTYPES.PROFILE}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>
                </nav>
            </div>

            <div className="sidebarSection">
                {/* <div className="sidebarHeading">Dengerin dari aplikasi</div> */}
                <nav>
                    <Link
                        href="https://play.google.com/store/apps/details?id=noice.app"
                        passHref
                        prefetch={false}
                    >
                        <a target="_blank" rel="noreferrer">
                            <NavItem>
                                <Android />
                                <Title>Android</Title>
                            </NavItem>
                        </a>
                    </Link>
                    <Link
                        href="https://apps.apple.com/id/app/noice-podcast-radio-musik/id1484372791?l=id"
                        passHref
                        prefetch={false}
                    >
                        <a target="_blank" rel="noreferrer">
                            <NavItem>
                                <Apple />
                                <Title>IOS</Title>
                            </NavItem>
                        </a>
                    </Link>
                </nav>
            </div>

            <div
                className="sidebarSection"
                style={{ borderBottom: '4px solid #0a0a0a' }}
            >
                {/* <div className="sidebarHeading">Lainnya</div> */}
                <nav>
                    <Link href={'/tnc'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.SYARAT_DAN_KETENTUAN)
                                    ? `active`
                                    : ''
                            }
                        >
                            <NavItem
                                onClick={() =>
                                    switchNav(NAVTYPES.SYARAT_DAN_KETENTUAN)
                                }
                            >
                                <Title
                                    active={isNavActive(
                                        NAVTYPES.SYARAT_DAN_KETENTUAN
                                    )}
                                >
                                    {NAVTYPES.SYARAT_DAN_KETENTUAN}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>
                    {/* <Link href={'/'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.BUKA_APP) ? `active` : ''
                            }
                        >
                            <NavItem
                                onClick={() => switchNav(NAVTYPES.BUKA_APP)}
                            >
                                <BukaApp
                                    active={isNavActive(NAVTYPES.BUKA_APP)}
                                />
                                <Title active={isNavActive(NAVTYPES.BUKA_APP)}>
                                    {NAVTYPES.BUKA_APP}
                                </Title>
                            </NavItem>
                        </a>
                    </Link> */}

                    <BukaApp isSidebar />

                    <Link href={'/policy'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.KEBIJAKAN_PRIVASI)
                                    ? `active`
                                    : ''
                            }
                        >
                            <NavItem
                                onClick={() =>
                                    switchNav(NAVTYPES.KEBIJAKAN_PRIVASI)
                                }
                            >
                                <Title
                                    active={isNavActive(
                                        NAVTYPES.KEBIJAKAN_PRIVASI
                                    )}
                                >
                                    {NAVTYPES.KEBIJAKAN_PRIVASI}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>
                </nav>
            </div>
        </div>
    )
}
