import React from 'react'

const Home = ({ active }) => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.55083 1.30583L1.375 5.78417C0.935833 6.1025 0.594166 6.50083 0.359166 6.96083C0.1225 7.42333 0 7.93417 0 8.47417V17.1742C0 18.0883 0.375833 18.92 0.981667 19.5233C1.58583 20.1258 2.41833 20.5 3.33333 20.5H16.6667C17.5817 20.5 18.4142 20.1258 19.0183 19.5233C19.6242 18.92 20 18.0875 20 17.1742V8.47417C20 7.93417 19.8775 7.42333 19.6408 6.96083C19.4058 6.50083 19.0642 6.1025 18.625 5.78417L12.4492 1.30583C11.7075 0.768333 10.8542 0.5 10 0.5C9.14583 0.5 8.2925 0.768333 7.55083 1.30583ZM2.35083 7.13167L5.905 4.555L8.5275 2.65333C8.975 2.32917 9.48833 2.16667 10 2.16667C10.5117 2.16667 11.025 2.32917 11.4725 2.65333L17.6492 7.13167C17.8775 7.2975 18.0508 7.49583 18.1633 7.71667C18.275 7.935 18.3333 8.19083 18.3333 8.47417V17.1742C18.3333 17.6317 18.1467 18.0458 17.8467 18.345C17.5442 18.6467 17.1267 18.8333 16.6667 18.8333H3.33333C2.87333 18.8333 2.45583 18.6467 2.15333 18.345C1.85333 18.0458 1.66667 17.6317 1.66667 17.1742V8.47417C1.66667 8.19083 1.725 7.935 1.83667 7.71667C1.94917 7.49583 2.1225 7.2975 2.35083 7.13167ZM12.9467 8.78254C12.1925 8.02837 11.15 7.56171 10 7.56171C8.85001 7.56171 7.80751 8.02837 7.05334 8.78254C6.30001 9.53588 5.83334 10.5784 5.83334 11.7284C5.83334 12.8784 6.30001 13.9209 7.05334 14.6751C7.80751 15.4284 8.85001 15.8951 10 15.8951C11.15 15.8951 12.1925 15.4284 12.9467 14.6751C13.7 13.9209 14.1667 12.8784 14.1667 11.7284C14.1667 10.5784 13.7 9.53588 12.9467 8.78254ZM10 9.22838C10.6908 9.22838 11.3158 9.50838 11.7675 9.96088C12.22 10.4125 12.5 11.0376 12.5 11.7284C12.5 12.4192 12.22 13.0442 11.7675 13.4967C11.3158 13.9484 10.6908 14.2284 10 14.2284C9.30918 14.2284 8.68418 13.9484 8.23251 13.4967C7.78001 13.0442 7.50001 12.4192 7.50001 11.7284C7.50001 11.0376 7.78001 10.4125 8.23251 9.96088C8.68418 9.50838 9.30918 9.22838 10 9.22838Z"
                fill={active ? '#FAD810' : '#888888'}
            />
        </svg>
    )
}
export default Home
